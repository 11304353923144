import React,{useState,useEffect, lazy,Suspense} from "react";

import './Application1.css';

import Navbar from '../components/Navbar/Navbar';
import DiseaseGeneDetails from '../app_components/DiseaseGeneDetails/DiseaseGeneDetails';
import Application1Data from '../data/Application1Data';
import chromosome1 from '../assets/images/Genome/chr1/1-zoomed.png'
import Modal2 from '../app_components/Modal/Modal2';
import GeneInformation from '../app_components/GeneInformation/GeneInformation';
import GeneDiseaseList from '../app_components/GeneDiseaseList/GeneDiseaseList';
import List from '../app_components/List/List';
import GeneDetails from '../app_components/GeneDetails/GeneDetails';
import MainImage from '../app_components/MainImage/MainImage';


import pin from '../assets/images/pin.png';
import BluePin from '../assets/images/BluePin.png';
import PinkPin from '../assets/images/PinkPin.png';
import BlackPin from '../assets/images/BlackPin.png';
import RedPin from '../assets/images/pin.png';
import YellowPin from '../assets/images/YellowPin.png';
import RedPin2 from '../assets/images/RedPin2.png';
import Tick from '../assets/images/tick.png';
import Green from '../assets/images/Green.png';
import GreenPin from '../assets/images/GreenPin.png';

import locations from '../data/location';
import getPixelLocation from '../data/shared';

function Application() {
  const [tableData, setTableData] = useState([]);
  const [tab, setTab] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [chromImage, setChromImage] =useState("");
  const [chromId, setChromId] =useState("");
  const [chromText, setChromText] =useState("");
  const [chromMarker, setChromMarker] = useState("");

  const [imgIndex, setImgIndex] = useState("");

  const [selectedName,setSelectedName] = useState("");
  const [selectedId,setSelectedId] = useState(-1);
  const [selectedType,setSelectedType] =useState(true);
  const [traitList,setTraitList] = useState([]);
  const [tabular2,setTabular2] =useState([]);

  const [disease,setDisease] =useState([]);
  const [genes,setGenes] =useState([]);
  const [all,setAll] = useState([]);
  const [chromosome,setChromosome] = useState([]);
  const [chromosome2,setChromosome2] =useState([]);
  
  const [sym, setSym] =useState('A');
  const [details,setDetails] =useState(false);

  const [diseaseName,setDiseaseName] =useState("Alzheimers disease");
  const [numberOfGenes,setNumberOfGenes] = useState(11);
  const [selectedList,setSelectedList] = useState([]);
  const [selectedListId,setSelectedListId] = useState([]);

  const [type, setType] = useState("D");

  const [tabular,setTabular] =useState([]);

  var list =["Alzheimers","diabetes","Hearyt isease","lUNG DISEASE"];
  const [currentIndex, setCurrentIndex] =useState(1);
 const [colorValue, setColorValue] = useState(-1);
  const[current,setCurrent] = useState(true);
  
  const [tabName,setTabName] =useState("");
  const [tabType,setTabType] =useState("");
  const [tabColor,setTabColor] =useState("");

  const [selectedId2,setSelectedId2] =useState("");
  const [selectedName2,setSelectedName2] =useState("");
  
  const [colore,setColore] =useState('');
  const [COLOR, setCOLOR] = useState([{value:RedPin2,used:false}, {value:BluePin,used:false},{value:GreenPin,used:false},{value:PinkPin,used:false},{value:YellowPin,used:false}]);
  
  const [colorV, setColorV] =useState("red");

  const [cindex, setCindex] =useState(0);


  const [dupList, setDupList] =useState([]);
  const [geneColorButton,setGeneColorButton] =  useState('#141414');
  const [diseaseColorButton,setDiseaseColorButton] = useState('#add8e6');

  const [geneColorFont, setGeneColorFont]= useState('white');
  const [diseaseColorFont, setDiseaseColorFont] = useState('black');


  
  const openModal =() =>{
      setShowModal(prev => !prev);
  }
  const removeAll =() =>{
        setSelectedList([]); 
        setColorValue(-1);
        setTabular([]);
        setChromosome2([]);
        setDupList([]);
        setCOLOR(COLOR.map(item =>{  
            return{
            ...item, used: false
        }
        
        return item;
        }))
  }

  console.log(Application1Data);
 ;
  const handleChange=(e)=>{
        setTab(e.target.value.toLowerCase());
  }

  
const table0 = ['20px','60px','100px','160px'];

// set disease tableData   

useEffect(() =>{
  setChromosome2(chromosome2);
},[selectedList,chromosome2]);

useEffect(() =>{
    setSelectedList(selectedList);
    setDupList(dupList);
  },[selectedList,setDupList]);
  

  useEffect(() =>{
      const getDiseasesData = async () =>{
        await fetch('/diseases',{mode:'no-cors'})
        .then((response) => response.json())
        .then((data) => {
        console.log(data);
        setDisease(data);
        setTableData(data.data);

   })
   }
   getDiseasesData();
   
   },[]);
   
 

   useEffect(() =>{
      const getDiseasesData = async () =>{
        await fetch('/genes',{mode:'no-cors'})
        .then((response) => response.json())
        .then((data) => {
        console.log(data);
        setGenes(data);
   })
   }

   getDiseasesData();
   },[]);

// changes to form
useEffect(()=>{
      const results = tableData.filter(person =>
          person.name.toLowerCase().startsWith(tab)
  
        );
        setSearchResults(results);
   },[tab])

  const [colorFlag,setColorFlag] = useState(false);


   useEffect(() =>{       
    const getDiseasesData = async () =>{    
      let flag = false;
      console.log(COLOR);

      COLOR.forEach((elem) =>{
          if(elem.used === false && !flag){
            setColore(elem.value);
            elem.used = true;
            flag =true;
          }
      })
      setCOLOR(COLOR);
      type === 'D'?     
      await fetch(`/diseaseDetails/${selectedId}`,{mode:'no-cors'})
      .then((response) => response.json())
      .then((data) => {
      console.log(data);
      data.data.map((ans,i)=>{
          chromosome2.push(ans);
          console.log(chromosome2);
          var marker_value = getPixelLocation(chromosome2[chromosome2.length-1]['chromosome'], chromosome2[chromosome2.length-1]['location']);
          marker_value = 100 -marker_value;
          chromosome2[chromosome2.length-1]['modal_marker'] =  marker_value+'px';
          marker_value = marker_value +'%';
          chromosome2[chromosome2.length-1]['marker_value'] =  marker_value;
          chromosome2[chromosome2.length-1]['color'] = colore;
          setColorFlag(false);
          chromosome2[chromosome2.length-1]['id'] = selectedId;
          chromosome2[chromosome2.length-1]['type'] = type;
          chromosome2[chromosome2.length-1]['dName']=selectedName;
          //chromosome2[chromosome2.length-1]['id'] = selectedId;
          setChromosome2(chromosome2);
          console.log(chromosome2);
          console.log(selectedList);
          console.log(dupList);
          

      })
      if(selectedList.length !== 0){
      tabular.push({id:selectedId, name:selectedName , type:type, color:colore});
      setTabular(tabular);
      }
      setChromosome(data);
      setNumberOfGenes(data.data.length);    
 }):
 await fetch(`/genesDetails/${selectedId}`,{mode:'no-cors'})
 .then((response) => response.json())
 .then((data) => {
    console.log(data);
    data.data.map((ans,i)=>{
        chromosome2.push(ans);
        var marker_value = getPixelLocation(chromosome2[chromosome2.length-1]['chromosome'], chromosome2[chromosome2.length-1]['location']);
        marker_value = 100 -marker_value;
        chromosome2[chromosome2.length-1]['modal_marker'] =  marker_value+'px';
        marker_value = marker_value +'%';
        chromosome2[chromosome2.length-1]['marker_value'] =  marker_value;
          
        //chromosome2[chromosome2.length-1]['id'] = selectedId; 
        chromosome2[chromosome2.length-1]['color'] = colore;
        setColorFlag(false);
        setChromosome2(chromosome2);   

})
if(selectedList.length !== 0){
    tabular.push({id:selectedId, name:selectedName , type:type, color:colore});
    setTabular(tabular);
    }
      setChromosome(data);
      setNumberOfGenes(data.data.length);    
})
 }
 getDiseasesData();
 },[selectedId]);

 
 const geneClick =() =>{  
    setSelectedType(false);
    setTableData(genes.data);
    setCurrent('G');
    setType("G");
    setGeneColorButton('#add8e6');
    setGeneColorFont('black');
    setDiseaseColorButton('#141414');
    setDiseaseColorFont('white'); 

    
 }


 const addALL =  () =>{
       if(selectedList.length === 0){
           alert("List cannot  be empty");
       }
       else{
          selectedList.map((data,i)=>{
          //addDisease(data.id);  
          console.log(data);
          setSelectedId2(data.id); 
          setSelectedName2(data.name);
          
          })
       }
       console.log('disease list');
       console.log(chromosome2);
       console.log("tabular");
       console.log(tabular);
       alert("added");
       console.log(currentIndex);
 }

 
 const diseaseClick =() =>{
    
    setSelectedType(true);
    setTableData(disease.data);
    setCurrent('D');
    setType("D");
    setGeneColorButton('#141414');
    setGeneColorFont('white');
    setDiseaseColorButton('#add8e6');
    setDiseaseColorFont('black'); 
 }

 


  return (
    <>
    <Navbar />
      <div className="Application1">
          <div className="Application">
              <div className="Sidebar">
                  <div className="sidebar-head">
                      <div className="sidebar-head-button">
                          <button style={{backgroundColor:`${geneColorButton}`, color:`${geneColorFont}`}} onClick={geneClick}>GENE</button>
                      </div>
                      <div className="sidebar-head-button">
                          <button style={{backgroundColor:`${diseaseColorButton}`, color:`${diseaseColorFont}`}} onClick={diseaseClick}>DISEASES</button>
                      </div>
                  </div>
                  <div className="sidebar-search">
                     <form>
                         <input type="text" name="search" placeholder="Search" value ={tab} onChange={handleChange}/>
                     </form>
                  </div>
              
                  <div className="sidebar-main-content">
                      <GeneDiseaseList 
                      tab={tab} 
                      tableData={tableData} 
                      searchResults={searchResults}
                      selectedList={selectedList}
                      setSelectedList={setSelectedList}
                      setSelectedName={setSelectedName} 
                      setSelectedId={setSelectedId}
                      type={type}
                      dupList={dupList}
                      setDupList={setDupList}
                      setColorValue={setColorValue}
                      colore={colore}
                      traitList={traitList}
                      />
                  </div>
                  
                  <div sidebar-main-list style={{marginTop:0}}>
                    
                    <List selectedList={selectedList} setSelectedList={setSelectedList} chromosome2={chromosome2} setChromosome2={setChromosome2} tabular={tabular} setTabular={setTabular} COLOR={COLOR} setCOLOR={setCOLOR}
                     colore={colore} setColore={setColore} dupList={dupList}
                     setDupList={setDupList}/> 
                  
                  </div>
                  <div className="sidebar-head">
                      <div className="sidebar-head-button">
                          <button onClick={addALL}>SUBMIT</button>
                      </div>
                      <div className="sidebar-head-button">
                          <button onClick={removeAll}>CLEAR</button>
                      </div>
                  </div>


              </div>
          
          <div className="description-section">
              <MainImage chromosome2={chromosome2} setChromosome2={setChromosome2} chromosome={chromosome} />
          </div>
      </div>
      
      {selectedList.length !== 0?
      <div className="chromosome">
      <div className="geneInformation">
                  
                 <DiseaseGeneDetails tabular={tabular} colore={colore}/>
                 <GeneDetails chromosome2={chromosome2} setChromosome2={setChromosome2}/>
                          
     </div>
     </div>
:null
      }
  </div>
  </>
  )
}

export default Application;
