import React from 'react'
import ImageSlider2 from './ImageSlider2';
import ImageSlideData from './ImageSliderData';
import "./ImageSlider.css";
import {Link} from 'react-router-dom';

const ImageSlider1 =() => {
   
    const imagedata = ImageSlideData.map(item => <ImageSlider2 key={item.id} item={item}/>)
    
    return (
        <div className="main_slider">
            <div className="headerArticles"><h1 style={{marginLeft:20,fontSize:40, textDecorationColor: ' #242424',textDecorationThickness: 4}}>Related Articles</h1></div>
        <div className="slider2">
            {imagedata}
        </div>
        </div>
    )
}

export default ImageSlider1
