import React from 'react'

import pin from '../../assets/images/pin.png';
import BluePin from '../../assets/images/BluePin.png';
import PinkPin from '../../assets/images/PinkPin.png';
import BlackPin from '../../assets/images/BlackPin.png';
import RedPin from '../../assets/images/pin.png';
import Tick from '../../assets/images/tick.png';

function GeneDetails({chromosome2}) {
    return (
        
            <div className="table-gene">
                      <table class="styled-table">
                          <thead>
                              <tr>    
                              <td>Sr no</td>    
                              <td>Gene Name</td>
                              <td>Gene Location</td> 
                              <td>Chromosome number</td> 
                              <td>Marker value</td>
                            
                              <td>Marker</td>
                              </tr>
                          </thead>
                          <tbody>
                              {
                              chromosome2 && chromosome2.map((detail,i) =>(
                                  <tr>
                                  <td>{i+1}</td>
                                  <td style={{fontStyle:'italic', color:'#00008B',textDecoration:'none', textAlign:'center'}}><a target="_blank" href={"https://www.ncbi.nlm.nih.gov/gene/"+detail.geneID}>{detail.name}</a></td>
                                  <td>{detail.location}</td> 
                                  <td>{detail.chromosome}</td>
                                  <td>{detail.marker_value}</td>
                                  
                                  <td><img src={detail.color}  style={{height:20, width:20, position:'absolute'}}/></td>
                                  </tr>
                                 ))}
                          </tbody>
                      </table>
                  </div>
        
    )
}

export default GeneDetails
