import React from 'react'
import './Modal.css';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import Carousel from '../Carousel/Carousel';

const ClosedModalButton = styled(MdClose)
` cursor: pointer;
  top:10px;
  width:32px;
  height:32px;
  padding:0;
  z-index:10;
  position:absolute; 
  right:10px;
  
`;

const Modal = ({showModal, setShowModal,content,title}) => {


    return(
        <>
        {showModal ? (
         <div className="Background">
            <div className="ModalWrapper">
            <ClosedModalButton aria-label="close-modal" onClick={() =>{ setShowModal(false)}} />
              <Carousel />
               
             
            </div>
         </div>
       
       ):null}
       </>
       )      
}
export default Modal
