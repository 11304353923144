import chromosome1 from '../../assets/images/chromosome1.jpg'; 
import chromosome2 from '../../assets/images/chromosome2.jpg'; 
import chromosome3 from '../../assets/images/chromosome3.jpg'; 
import lab from '../../assets/images/lab.png'; 
import UTA from '../../assets/images/UTA.jpg'; 
import chromosomes10 from '../../assets/images/chromosomes10.png'; 


export const homeObjOne = {
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'Data Source',
    headline: 'Data storage and Retreival',
    description:'The data has been retrieved from public the National Center for Biotechnology (NCBI) Information, National Library of Medicine (NLM), National Institutes of Health, Health and Human Services. Both the known genes and the Genome-wideassociation study (GWAS) are included. The data can be foundat www.ncbi.nlm.nih.gov.How about:The data has been retrieved from public sources, such as the National Center for Biotechnology Information (NCBI), National Library of Medicine (NLM) National Institutes of Health, Health and Human Services. Both the known genes andthe Genome-wide association study (GWAS) are included. The data can be foundat www.ncbi.nlm.nih.gov.',
    buttonlabel:'Get Started',
    imgStart:false,
    img: chromosomes10,
    alt:'car',
    dark:false,
    primary:false,
    darkText:true
  
   }
  
   
export const homeObjTwo = {
    id:'discover',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'About',
    headline: 'Use of the Application',
    description:'It is aimed at anyone curious about where a gene might be located on a chromosome and what we know about that gene. How many sites are there for height? Where does a gene for Cystic Fibrosis sit? Do some genes reside on more than one chromosome?    This app is designed to answer just these questions.',
   
    buttonlabel:'Learn more',
    imgStart:true,
    img: lab,
    alt:'car',
    dark:false,
    primary:false,
    darkText:true
  
   }

   
export const homeObjThree = {
    id:'about',
    lightBg:true,
    lightText:true,
    lightTextDesc:true,
    topLine:'History',
    headline: 'Inception of the App',
    description:'Originally developed at the National Institutes of Health such images as the Chromosome details. The first version of the data was supplied by Dr. Teri Manollo,also from NHGRI supplied advice and the original data on the GWAS studies. Many others from from NLM pointed us to the appropriate databases and provided guidance on their work. pointed us to the appropriate databases and provided guidance on their work. The App is now integrated into the North Texas Genome Center and it multiple offerings to the genetic sequencing community. Gilbert Kipkorir, a graduate student at the University of Texas at Arlington has created an entirely new app, that while it looks and has functionality to the first version, is much more versatility and additional features',
    buttonlabel:'Learn more',
    imgStart:true,
    img: UTA,
    alt:'car',
    dark:true,
    primary:true,
    darkText:true
  
   }
  
  
   export const homeObjFour = {
    id:'about',
    lightBg:true,
    lightText:false,
    lightTextDesc:false,
    topLine:'Instructions',
    headline: 'Application Working',
    description:'One or more Genes (up to 4) can be selected at any one time for viewing. The location of the gene then is highlighted in the chromosome array. A user can then tap the chromosome and the chromosome with the marker zooms out. Tapping on the marker itself will then zoom out an additional view expanding the information about the gene with a hyperlink to the NCBI data site.',
    
    buttonlabel:'Get Started',
    imgStart:false,
    img: chromosome3,
    alt:'car',
    dark:false,
    primary:false,
    darkText:true
  
   }
  
  