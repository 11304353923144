import React from 'react'

function GeneDiseaseList({tab,tableData,selectedList, setSelectedList, setSelectedName, setSelectedId,type , setColorValue,searchResults,setTraitList,traitList,colore,COLOR,setCOLOR,setColore,dupList,setDupList}) {

    console.log(selectedList);
    return (

        <div>
            <div className="table" style={{height:350}}>
                {tab === "" && tableData?
                              
                                  tableData.slice(0, 600).map((data) =>(
                                      data.name === 'DiseaseName'? null:
                                      <tr>
                                      <td>{data.name.toLowerCase()}</td> 
                                      <td className="todo-button" 
                                      onClick={() =>{
                                        if(!dupList.includes(data.name) && selectedList.length < 4){
                                        setSelectedId(data.id);  
                                        setSelectedName(data.name);
                                        selectedList.push({name:data.name, type:type,id:data.id,color:colore})&& setSelectedList(selectedList); 
                                        dupList.push(data.name) && setDupList(dupList);
                                        console.log(selectedList);
                                         //alert("Only 4 at a time");
                                        }
                                        else{
                                            if(dupList.includes(data.name)){
                                                alert("Trait already Present in the List");
                                            }
                                            else{
                                                  
                                            alert("Only 4 at a time");
                                            }
                                        }
                                        
                                        }}><i class="fa fa-plus-circle" aria-hidden="true"></i></td>
                                      </tr>
                          )):
                          searchResults.map((data) =>(
                            data.name === 'DiseaseName'? null:
                              <tr> 
                                  <td>{data.name.toLowerCase()}</td>
                                  <td className="todo-button" 
                                  onClick={() =>{
                                    if(!dupList.includes(data.name) && selectedList.length < 4){
                                        setSelectedId(data.id);  
                                        setSelectedName(data.name);
                                        selectedList.push({name:data.name, type:type,id:data.id,color:colore})&& setSelectedList(selectedList); 
                                        dupList.push(data.name) && setDupList(dupList);
                                        
                                         //alert("Only 4 at a time");
                                        }
                                        else{
                                            if(dupList.includes(data.name)){
                                                alert("Trait already Present in the List");
                                            }
                                            else{
                                                  
                                            alert("Only 4 at a time");
                                            }
                                        }
                                        



                                    }}><i className="fas fa-plus-square"></i></td>
                                     
                              </tr>))
                          }
                      </div>
                                  
            
        </div>
    )
}

export default GeneDiseaseList
