import viewOne from '../../assets/images/modal_1.png'
import viewTwo from '../../assets/images/modal_2.png'
import viewThree from '../../assets/images/modal_3.png'
//import viewFour from '../../assets/images/viewFour.png'


const CarouselData = [
{
    
    
    image: viewOne,
    
    },{
        
    
    image: viewTwo,
    

    },{
        
    
    image: viewThree,
   
    },
    
    
]


export default CarouselData;
