import React from 'react'
import InfoSection from '../components/InfoSection/InfoSection';
import {homeObjOne, homeObjTwo, homeObjThree,homeObjFour } from '../components/InfoSection/Data';
import Stepper from '../components/Stepper/Stepper';
import Headers from '../components/Headers/Headers';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

function AboutUs() {
    return (
    <div>
    <Navbar />
     <Headers title="About us"/>            
     <InfoSection  {...homeObjTwo}/>
     <InfoSection  id="section2"  {...homeObjOne}/>
     <InfoSection {...homeObjThree}/>
     <InfoSection {...homeObjFour}/>
     <Footer />
    </div>
    )
}

export default AboutUs
