import React from 'react'
import { FaLinkedin } from 'react-icons/fa';
import {Link} from 'react-router-dom';
import './Footer.css';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter} from 'react-icons/fa';
import Logo from '../../assets/images/icon.png'

const Footer = () => {
    return (
        
< div className="FooterContainer">
  <div className="FooterWrapper">
    <div className="SocialMedia">   
    <div className="SocialMediaWrap">
      <div className="FooterImage"><img src={Logo}/></div>
    <div className="SocialLogo" to="/">North Texas Genome center</div>
  
    <div className="WebsiteRights">North Texas Genome center &#169; {new Date().getFullYear()} All rights reserved</div>
    <div className="SocialIcons">
        <div className="SocialIconLink" href ="https://www.instagram.com/utarlington/?hl=en" target="_blank"
        aria-label="Facebook">
        <FaFacebook />
        </div>

        
        <div className="SocialIconLink" href ="/" target="_blank"
        aria-label="Instagram">
        <FaInstagram />
        </div>

        

        
        <div className="SocialIconLink" href ="https://twitter.com/utarlington?lang=en" target="_blank"
        aria-label="Twitter">
        <FaTwitter/>
        </div>

        
        <div className="SocialIconLink" href ="/" target="_blank"
        aria-label="linkedin">
        <FaLinkedin/>
        </div>

        </div>
    </div>
    </div>
  </div>
</div>
   

    )
}

export default Footer;
