import React from "react";
import './Carousel.css';
import { useState } from 'react';
import CarouselData from './CarouselData';

import {FaArrowCircleRight,FaArrowCircleLeft} from 'react-icons/fa';

function Carousel(){

    const[currImg,setCurrImg] = useState(0);
 return(
     <>
     <div className="carousel-container">
     
     <div className="carousel">
        <div className="carouselInner" 
        style ={{ backgroundImage:`url(${CarouselData[currImg].image})`}}>
             <div className="left-c" onClick={() =>{
                    currImg >0 && setCurrImg(currImg-1);
                 }}>
                 <FaArrowCircleLeft  />
             </div>
             <div className="center-c"></div>
             <div className="right-c" onClick={() =>{
                    currImg < CarouselData.length-1 && setCurrImg(currImg+1);
                 }}>
                 <FaArrowCircleRight />
             </div>
             
        </div>
     </div>
     </div>
     </>
 )


}


export default Carousel;

