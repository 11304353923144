import React from 'react';
import './BodySection.css';



function BodySection(){
	
return(
   <div className ="body-container">
       <div className="H_container">
       <h1>HUMAN</h1>
       <h1 style={{color: 'red'}}>GENOME </h1>
       <h1>CENTER</h1>
       </div>
       <p></p>
	<div className="body-btns">
	   
        
	</div>
   </div>

);


}

export default BodySection;
