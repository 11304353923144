import React,{useState} from 'react'
import "./ImageSlider.css";
import Modal from '../Modal/Modal';

const ImageSlider2 = (props) => {

    const [showModal, setShowModal] = useState(false);
    const openModal =() =>{
        
        window.open(props.item.link);
                      
    }

    return (
        <>
         <Modal showModal={showModal}  setShowModal={setShowModal} content={props.item.content} title={props.item.title}/> 
        <div className="box0">
            <div className="box">
                <div className ="slide-img">
                    <img src ={props.item.image}/>
                     <div className = "overlay">
                        <button onClick ={ openModal}  className="buy-btn">Read more</button>   
                       
                    </div>   
                </div>
               <div className="detail-box">
                    <div className="type">
                        <a href="#" >{props.item.title}</a>
                       
                        <span style={{fontSize:12, fontStyle: 'italic'}}>{props.item.description}</span>
                        
                    </div>
                     <a href=""> </a>
                </div>
            </div>
        </div>
        </>

    )
}

export default ImageSlider2
