import React from 'react'
import Value from  './Value';
import './List.css';
import { MdColorize } from 'react-icons/md';

function List({selectedList,setSelectedList,chromosome2,setChromosome2,tabular,setTabular, COLOR, setCOLOR,colore,setColore, dupList, setDupList}) {
   console.log(selectedList);
   
    return (
        
            <div className="table" style={{height:200,marginTop:5}}>
                        
                        <th>
                            <td>SELECTED LIST</td>
                        </th>
                       {
                           selectedList && selectedList.map((todo) =>(
                               <tr>
                                   <td style={{width:250}}>{todo.name.toLowerCase()}</td>
                                   <td>
                                   <button className="complete-btn"><i  className="fas fa-check"></i></button>
                                   <button className="trash-btn" onClick={() =>{
                                
                                       setSelectedList(selectedList.filter(el => el.name != todo.name));
                                       todo.type === 'D'?
                                       setChromosome2(chromosome2.filter(el => el.id !== todo.id && todo.type !== 'G'|| el.id !== todo.id ))
                                       :setChromosome2(chromosome2.filter(el => el.id !== todo.id && todo.type !== 'D' || el.id !== todo.id ));
                                       
                                       setDupList(dupList.filter(el =>el != todo.name));
                                       
                                       setTabular(tabular.filter(el => el.name != todo.name));
                                       
                                        setCOLOR(COLOR.map(item =>{
                                            if(item.value === todo.color){
                                            return{
                                            ...item, used: false
                                        }
                                        }
                                        return item;
                                        }))
                                   }}><i  className="fas fa-trash"></i></button></td>
                                      
                                </tr>
                           ))
                       }
                    </div>
                    
        
    )
}

export default List
