import React from 'react'

import pin from '../../assets/images/pin.png';
import BluePin from '../../assets/images/BluePin.png';
import PinkPin from '../../assets/images/PinkPin.png';
import BlackPin from '../../assets/images/BlackPin.png';
import RedPin from '../../assets/images/pin.png';
import Tick from '../../assets/images/tick.png';


function DiseaseGeneDetails({tabular}) {
    return (
        
            <div className="table-gene">
                      <table class="styled-table">
                          <thead>
                              <tr>    
                              <td>Sr no</td>    
                              <td>Disease Name</td>
                              <td>Selected Type</td>
                              <td>Marker</td> 
                              
                              </tr>
                          </thead>
                          <tbody>
                              {
                              tabular && tabular.map((detail,i) =>(
                                  <tr>
                                  <td>{i+1}</td>
                                  <td>{detail.name}</td>
                                  <td>{detail.type === 'D'?`Disease`:`Gene` }</td>
                                  <td><img src={detail.color}  style={{height:20, width:20, position:'absolute'}}/></td>
                                   
                                  </tr>
                                  
                                 ))}
                          </tbody>
                      </table>
                  </div>

        
    )
}

export default DiseaseGeneDetails
