import locations from './locations';

export function calculatePixelLocation(chromosome, value) {
  const current = locations[chromosome];
  const arr = Object.keys(current);
  const arm = value.charAt(0);
  let lowerPixel = 0;
  let upperPixel = 100;
  let upperValue = null;
  let lowerValue = null;
  if (arm === "q") {
    const index = arr.findIndex(key => key > value );
    if (index === -1) {
      upperValue = arr[arr.length - 1];
      upperPixel = current[upperValue];
      lowerPixel = 0;

    } else {
      lowerValue = arr[index];
      upperValue = arr[index-1];
      upperPixel = current[upperValue];
      lowerPixel = current[lowerValue];
    }
  } else if (arm === "p") {
    const index = arr.findIndex(key => key < value);
    if (index === 0){
      upperPixel = 100
      lowerValue = arr[index]
      lowerPixel = current[lowerValue]
    } else {
      upperValue = arr[index-1]
      lowerValue = arr[index]
      upperPixel = current[upperValue]
      lowerPixel = current[lowerValue]
    }
  }
  let calcLocation = 0; 
  if (upperValue && value && lowerValue && upperValue) {
    const upperLessLower = upperPixel - lowerPixel;
    const floatValue = parseFloat(value.slice(2));
    const floatLowerValue = parseFloat(lowerValue.slice(2));
    const floatUpperValue = parseFloat(upperValue.slice(2));
    const fromlowerValue = (floatValue - floatLowerValue) / (floatUpperValue - floatLowerValue);
    calcLocation = lowerPixel + (fromlowerValue * upperLessLower);

  } else {
    calcLocation = (upperPixel + lowerPixel) / 2;
  }
  calcLocation = (upperPixel + lowerPixel) / 2; // TODO Just for testing remove
  return calcLocation;
}

export default function getPixelLocation(chromosome, value) {
  const new_value  = extractLocation(value);
  const current = locations[chromosome];
  let location = current[new_value]
  if (!location){
    location = calculatePixelLocation(chromosome, new_value);
  }
  return location;
}

function extractLocation(location) {
  const first_part = location.split('-')[0];
  let new_location = "";
  if ( first_part.includes('q') ) {
    new_location = 'q.' + first_part.split('q').pop()
  } else if ( first_part.includes('p') ) {
    new_location = 'p.' + first_part.split('p').pop()
  }
  return new_location;
}