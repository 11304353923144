import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/NTGCLogo.png';
import {Link} from 'react-router-dom';
import './Navbar.css';
import Modal from '../Modal/Modal';

function Navbar(){
    const [click,setClick] = useState(false);
    const [button,setButton] = useState(true);
    const [showModal, setShowModal] = useState(false);
    
    const openModal =() =>{
        setShowModal(prev => !prev);
    }


    const handleClick = () =>{
        setClick(!click);
    }
    const closeMobileMenu =() =>{
        setClick(false);
    }
     
    const showButton =() =>{
    if(window.innerWidth <= 960){
      setButton(false);
    }
    else{
      setButton(true);
    }    };

    
    useEffect(() => {
    showButton();
  }, []);
    window.addEventListener('resize',showButton);


        return(
            <>
            <nav className="navbar">
                <div className="navbar-container">

                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <img src ={Logo}  height="50px" width="50px"/>
                    
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ?"fas fa-times":"fas fa-bars"}></i>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                        HOME
                        </Link>
                    </li>
                    <li className='nav-item'>
                    <Link
                        to='/Application'
                        className='nav-links'
                        onClick={closeMobileMenu}
                    >
                        APP
                    </Link>
                    </li>
                    <li className='nav-item'>
                    <Link
                        to='/AboutUs'
                        className='nav-links'
                        onClick={closeMobileMenu}
                    >
                        ABOUT 
                    </Link>
                    </li>
                    
                    
                    <li className='nav-item'>
                    <button className="nav-button-2" onClick ={openModal}>HELP</button>
                    <Modal showModal={showModal}  setShowModal={setShowModal} content={"contant"} title={"title"}/> 
                    </li>
                    
                    
                    
          </ul> 
                            
                </div>
            </nav>
            </>


        );
}


export default Navbar;