import React from 'react'
import BodySection from '../components/BodySection/BodySection';
import Carousel from '../components/Carousel/Carousel';
import GeneInformation from '../components/GeneInformation/GeneInformation';
import ImageSlider1 from '../components/ImageSlider/ImageSlider1';
import Sidebar from '../components/Sidebar/Sidebar';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';


function Home() {
    return (
        <div>
            
            <Navbar />
            <BodySection />
            <Carousel />
            <ImageSlider1 />
            
            
      <Footer />
            
        </div>
    )
}

export default Home
