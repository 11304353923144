import './App.css';
import React from 'react';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import AboutUs2 from './Pages/AboutUs2';
import Help from './Pages/Help';
import Footer from './components/Footer/Footer';

import Application from './Pages/Application';


function App() {
  return (
    <>
    <Router>
      <Switch>
        <Route path ="/" exact component={Home} />
        <Route path ="/Application" exact component={Application} />
        <Route path ="/AboutUs" exact component={AboutUs} />
        <Route path ="/Help" exact component={Help} />
        <Route path="/#section1" component={AboutUs} />
      </Switch>
      
    </Router>
  </>
  );
}

export default App;
