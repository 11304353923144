import React from 'react'
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import './Modal3.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const ClosedModalButton = styled(MdClose)
` cursor: pointer;
  top:10px;
  width:32px;
  height:32px;
  padding:0;
  z-index:10;
  position:absolute; 
  right:10px;
  
`;

const Modal3 = ({showModal1, setShowModal1,position,geneId,geneName,modalMarker,location,dName}) => {
       console.log("MARKER");
       console.log(position);
       console.log(modalMarker);
    return(
        <>
        {showModal1 ? (
         <div className="Background2" style={{top:`${position}`}}>
            <div className="ModalWrapper1" onClick={() =>{ setShowModal1(false)}} >
              {dName?
              <div style={{display:"flex"}}>
              <i style={{fontWeight: "bold"}}>Name:</i> &nbsp;
              <i> {dName}</i>
              </div>:null}
              <div style={{display:"flex"}}>
              <i style={{fontWeight: "bold"}}>Gene Name:</i>&nbsp;
              <i>{geneName}</i>
              </div>
              <div style={{display:"flex"}}>
              <i style={{fontWeight: "bold"}}>Location:</i>&nbsp;
              <i>{location}</i>
              </div>
              <div style={{display:"flex"}}>
              <i style={{fontWeight: "bold"}}>Link:</i>&nbsp;
              <a target="_blank" href={"https://www.ncbi.nlm.nih.gov/gene/"+geneId}>Gene Details</a>
              </div>
              </div>
         </div>
       
       ):null}
       </>
       )      
}
export default Modal3
