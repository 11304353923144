import React, {useState, useEffect} from 'react'
import './Modal2.css';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import pin from '../../assets/images/pin.png';
import Image_Gene from '../Image_Gene/Image_Gene';
import Modal3 from '../Modal3/Modal3';
import Application2Data from '../../data/Application2Data';

const ClosedModalButton = styled(MdClose)
` cursor: pointer;
  top:10px;
  width:32px;
  height:32px;
  padding:0;
  z-index:10;
  position:absolute; 
  right:10px;
  
`;

const Modal2 = ({showModal, setShowModal,content,title,image,text,chromosome,chromId, chromosome2,setChromosome2,imageIndex }) => {
   const [present,setPresent] =useState(false);
   const [value,setValue] =useState(40);
   const [showModal1, setShowModal1] = useState(false);
   const [position,setPosition] = useState('20px');
   const [geneId, setGeneId] =useState("");
   const [geneName,setGeneName] =useState("");
   const [modalMarker,setModalMarker] = useState("");
   const [location,setLocation] =useState("");
   const [dName, setDname] =useState("");


   const pinDetails =() =>{
      alert("hello pins");
   }
   
const table0 = ['20px','60px','100px','160px'];
   var chrom_full ={};
   var chrom_count ={};
   var chrom_count2 ={};

    var dot ='blue';
   var actual =[];
   var actual2 =[];
   useEffect(()=>{
     if(chromosome.data !== undefined){
      
    chromosome.data.forEach(element => {
       if(!chrom_full.hasOwnProperty(element.chromosome)){
           chrom_full[element.chromosome] = {};
       }
       chrom_full[element.chromosome][element.location] = element.name;
       if(!chrom_count.hasOwnProperty(element.chromosome)){
         chrom_count[element.chromosome] = [];
     }
     if(!chrom_count2.hasOwnProperty(element.chromosome)){
      chrom_count2[element.chromosome] = [];
  }
     
     chrom_count[element.chromosome].push(element.location);  
     chrom_count2[element.chromosome].push(element.name);    
           
    })
    actual = chrom_count[chromId];
    actual2 = chrom_count2[chromId]
    console.log("actual");
    console.log(actual);
    console.log(chrom_count);
    console.log(chrom_count2);
    chrom_count.hasOwnProperty(chromId)?console.log("yes"):console.log("no");
    chrom_count.hasOwnProperty(chromId)?setPresent(true):setPresent(false);
   }
   })
   
    return(
        <>
        {showModal ? (
         <div className="Background">
            <div className="ModalWrapper">
            <ClosedModalButton aria-label="close-modal" onClick={() =>{ setShowModal1(false); setShowModal(false)}} />
            <div className="ModalContent">   
               <div className="modal_image">
               <div className="Image_Modal">
                  <img src={Application2Data[imageIndex].image}  />
                  
                  {chromosome2.map((marker) => (
                     chromId == marker.chromosome?
                     <img src={marker.color} 
                     key={marker.id} 
                     style={{height:20, width:20, position:'absolute',
                     top:`${marker.marker_value}`,
                     
                  
                     }}
                     className="pins"
                     onClick ={()=>{ setShowModal1(prev => !prev); setPosition(marker.marker_value); setGeneId(marker.geneID); setGeneName(marker.name); setModalMarker(marker.modal_marker); setLocation(marker.location); setDname(marker.dName);}}
                     />:null
      
                     ))}
                     <Modal3 showModal1={showModal1} setShowModal1={setShowModal1} position={position} geneId={geneId} geneName={geneName} modalMarker={modalMarker} location={location} dName={dName}/>
                  


               </ div>
               </div>
                  <div className="modal_information" >
                      <h2>{title}</h2>
                      <p>{text}</p>
                  </div>
               </div>
              
               
             
            </div>
         </div>
       
       ):null}
       
       </>
       
       )
       
       
}

export default Modal2
