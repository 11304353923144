import chromosome1 from '../assets/images/Genome/chr1/1.png'
import chromosome2 from '../assets/images/Genome/chr2/2.png'
import chromosome3 from '../assets/images/Genome/chr3/3.png'
import chromosome4 from '../assets/images/Genome/chr4/4.png'
import chromosome5 from '../assets/images/Genome/chr5/5.png'
import chromosome6 from '../assets/images/Genome/chr6/6.png'
import chromosome7 from '../assets/images/Genome/chr7/7.png'

import chromosome8 from '../assets/images/Genome/chr8/8.png'
import chromosome9 from '../assets/images/Genome/chr9/9.png'
import chromosome10 from '../assets/images/Genome/chr10/10.png'
import chromosome11 from '../assets/images/Genome/chr11/11.png'
import chromosome12 from '../assets/images/Genome/chr12/12.png'
import chromosome13 from '../assets/images/Genome/chr13/13.png'
import chromosome14 from '../assets/images/Genome/chr14/14.png'


import chromosome15 from '../assets/images/Genome/chr15/15.png'
import chromosome16 from '../assets/images/Genome/chr16/16.png'
import chromosome17 from '../assets/images/Genome/chr17/17.png'
import chromosome18 from '../assets/images/Genome/chr18/18.png'
import chromosome19 from '../assets/images/Genome/chr19/19.png'
import chromosome20 from '../assets/images/Genome/chr20/20.png'
import chromosome21 from '../assets/images/Genome/chr21/21.png'
import chromosome22 from '../assets/images/Genome/chr22/22.png'

import chromosomeX from '../assets/images/Genome/X/X.png'
import chromosomeY from '../assets/images/Genome/Y/Y-zoomed.png'

//import chromosome1 from '../../assets/images/chromosome8.jpg'

const Application1Data = [

    {

       id:1,
       image:chromosome1,
       text:"Chromosome 1 is the designation for the largest human chromosome. Humans have two copies of chromosome 1, as they do with all of the autosomes, which are the non-sex chromosomes. Chromosome 1 spans about 249 million nucleotide base pairs, which are the basic units of information for DNA."
    },
    {

        id:2,
        image:chromosome2,
        text:" Chromosome 2 is the second-largest human chromosome, spanning more than 242 million base pairs and representing almost eight percent of the total DNA in human cells"
     },
     {

        id:3,
        image:chromosome3,
        text:" Chromosome 3 spans almost 200 million base pairs and represents about 6.5 percent of the total DNA in cells"
     },{

        id:4,
        image:chromosome4,
        text:"Chromosome 4 spans more than 186 million base pairs and represents between 6 and 6.5 percent of the total DNA in cells."
     },{

        id:5,
        image:chromosome5,
        text:" Chromosome 5 spans about 181 million base pairs and represents almost 6% of the total DNA in cells. Chromosome 5 is the 5th largest human chromosome, yet has one of the lowest gene densities"
     },{

        id:6,
        image:chromosome6,
        text:"Chromosome 6 spans more than 170 million base pairs and represents between 5.5 and 6% of the total DNA in cells."
     },{

        id:7,
        image:chromosome7,
        text:"Chromosome 7 spans about 159 million base pairs and represents between 5 and 5.5 percent of the total DNA in cells"
     },{

        id:8,
        image:chromosome8,
        text:"Chromosome 8 spans about 145 million base pairs and represents between 4.5 and 5.0% of the total DNA in cells"
     },{

        id:9,
        image:chromosome9,
        text:" Chromosome 9 spans about 138 million base pairs of nucleic acids and represents between 4.0 and 4.5% of the total DNA in cells."
     },{

        id:10,
        image:chromosome10,
        text:" Chromosome 10 spans about 133 million base pairs and represents between 4 and 4.5 percent of the total DNA in cells."

     },{

        id:11,
        image:chromosome11,
        text:"Chromosome 11 spans about 135 million base pairs and represents between 4 and 4.5 percent of the total DNA in cells. The shorter arm is termed 11p while the longer arm is 11q."
     },{

        id:12,
        image:chromosome12,
        text:"Chromosome 12 spans about 133 million base pairs and represents between 4 and 4.5 percent of the total DNA in cells. Chromosome 12 contains the Homeobox C gene cluster"
     },{

        id:13,
        image:chromosome13,
        text:"Chromosome 13 spans about 114 million base pairs and represents between 3.5 and 4% of the total DNA in cells"
     },{

        id:14,
        image:chromosome14,
        text:"Chromosome 14 spans about 107 million base pairs and represents between 3 and 3.5% of the total DNA in cells. The centromere of chromosome 14 is positioned approximately at position 17.2 Mbp."
     },{

        id:15,
        image:chromosome15,
        text:"Chromosome 15 spans about 101 million base pairs and represents between 3% and 3.5% of the total DNA in cells. The human leukocyte antigen gene for β2-microglobulin is found at chromosome 15. "
     },{

        id:16,
        image:chromosome16,
        text:"Chromosome 16 spans about 90 million base pairs and represents just under 3% of the total DNA in cells"
     },{

        id:17,
        image:chromosome17,
        text:"Chromosome 17 spans more than 83 million base pairs and represents between 2.5 and 3% of the total DNA in cells. Chromosome 17 contains the Homeobox B gene cluster."
     },{

        id:18,
        image:chromosome18,
        text:"Chromosome 18 spans about 80 million base pairs and represents about 2.5 percent of the total DNA in cells."
     },
     {

        id:19,
        image:chromosome19,
        text:"People normally have two copies of this chromosome. Chromosome 19 spans more than 58.6 million base pairs, the building material of DNA."
     },
     {

        id:20,
        image:chromosome20,
        text:" Chromosome 20 spans around 63 million base pairs and represents between 2 and 2.5 percent of the total DNA in cells. Chromosome 20 was fully sequenced in 2001 and was reported to contain over 59 million base pairs."
     },
     {

        id:21,
        image:chromosome21,
        text:"Chromosome 21 is both the smallest human autosome and chromosome, with 48 million base pairs representing about 1.5 percent of the total DNA in cells."
     },
     {

        id:22,
        image:chromosome22,
        text:"Chromosome 22 is the second smallest human chromosome, spanning about 49 million DNA base pairs and representing between 1.5 and 2% of the total DNA in cells"
     },
     {

        id:'X',
        image:chromosomeX,
        text:"The X chromosome is one of two sex chromosomes. Humans and most mammals have two sex chromosomes, the X and Y. Females have two X chromosomes in their cells, while males have X and Y chromosomes in their cells. Egg cells all contain an X chromosome"
     },
     {

        id:'Y',
        image:chromosomeY,
        text:"The Y chromosome is one of two sex chromosomes (allosomes) in therian mammals, including humans, and many other animals. The other is the X chromosome. Y is normally the sex-determining chromosome in many species, since it is the presence or absence of Y that determines the male or female sex of offspring produced in sexual reproduction. In mammals, the Y chromosome contains the gene SRY, which triggers male development. The DNA in the human Y chromosome is composed of about 59 million base pairs"
     },

    

];


export default Application1Data;