import React,{useEffect,useState} from 'react';
import './GeneInformation.css';



function GeneInformation(){

    const [chromosome,setChromosome] = useState([]);
    var table5 =[];
    
    useEffect(() =>{
        const getDiseasesData = async () =>{
          await fetch('http://ntgc.us/diseasesGenes')
          .then((response) => response.json())
          .then((data) => {
          console.log(data);
          setChromosome(data);
     })
     }
  
     getDiseasesData();
     },[]);

     useEffect(() =>{
        const getDiseasesData = async () =>{
          await fetch('http://ntgc.us/examples/Alzheimer disease')
          .then((response) => response.json())
          .then((data) => {
          console.log(data);
          setChromosome(data);
     })
     }
  
     getDiseasesData();
     },[]);


     if(chromosome.length !== 0){
        for(var i=0;i<chromosome.data.length;i++)
        {
         table5.push(chromosome.data[i]);
        }
          console.log(table5);
      }
	
return(
   <div className="geneInformation">
      <div className="card_box">    
      <div className="card">
        <div className = "container1">
          <div className="card-header" style={{backgroundColor:'#009879',color:'#ffffff', height:30,
  paddingTop: 12,  textAlign: 'left'}}>
            Disease Details
          </div>
          <h4 className="card_info"><b>Disease Name</b></h4><span className="card_span" style={{display:'inline'}}>Alzheimers disease</span> 
          <h4 className="card_info"><b>Numberof Genes Associated</b></h4><span className="card_span"style={{display:'inline'}}>8</span> 
          <h4 className="card_info"><b>Disease description</b></h4><span className="card_span"style={{display:'inline'}}>Alzheimer's is the most common cause of dementia, a general term for memory loss and other cognitive abilities serious enough to interfere with daily life. Alzheimer's disease accounts for 60-80% of dementia cases.</span> 
        </div>
      </div>
      </div>
      <div className="table-gene">
        <table class="styled-table">
                        <thead>
                        <tr>    
                        <td>Sr no</td>    
                        <td>Gene Name</td>
                        <td>Gene Location</td> 
                        <td>Chromosome number</td> 
                        </tr>
                        </thead>
                        <tbody>
                        {
                        table5.map((data,i) =>(
                            <tr>
                            <td>{i+1}</td>
                            <td>{data.name}</td>
                            <td>{data.location}</td> 
                            <td>{data.chromosome}</td>   
                            </tr>
                ))

}
</tbody>
        </table>
   </div>
   </div>
);


}

export default GeneInformation;
