const locations = {
    "1": {
      "p.36.32": 99,
      "p.36.21": 95.5,
      "p.36.2": 91,
      "p.33": 88,
      "p.32.2": 84.5,
      "p.31.3": 74,
      "p.31.1": 71,
      "p.22.3": 67,
      "p.21.1": 60,
      "p.13.2": 56,
      "p.11.2": 53,
      "centromere": 51,
      "q.12": 49,
      "q.21.1": 42.5,
      "q.21.3": 39,
      "q.23.1": 37.5,
      "q.21.3": 36.5,
      "q.23.1": 32.5,
      "q.24.2": 29,
      "q.25.2": 26.5,
      "q.31.1": 22,
      "q.32.1": 15,
      "q.41": 12,
      "q.42.13": 9,
      "q.42.3": 6,
      "q.44": 2,
      },
    "2": {
      "p.25.2": 97.5,
      "p.24.3": 92.5,
      "p.23.3": 88.5,
      "p.22.3": 85,
      "p.21": 81,
      "p.16.2": 77,
      "p.14": 73,
      "p.13.1": 70,
      "p.12": 67,
      "p.11.2": 64,
      "centromere": 61,
      "q.11.1": 60.5,
      "q.12.1": 58,
      "q.13": 53.5,
      "q.14.2": 50,
      "q.21.1": 46.5,
      "q.21.3": 43,
      "q.22.2": 41,
      "q.23.3": 38,
      "q.24.2": 34,
      "q.31.1": 30,
      "q.31.2": 26.5,
      "q.32.1": 23.5,
      "q.32.3": 21,
      "q.33.1": 18,
      "q.33.4": 16.5,
      "q.35": 11,
      "q.36.2": 7,
      "q.37.1": 4.5,
      "q.37.3": 2,
      },
    "3": {
      "p.26.2": 98,
      "p.25.3": 95,
      "p.24.3": 91,
      "p.24.1": 88,
      "p.22.3": 84,
      "p.21.33": 80.5,
      "p.21.31": 77,
      "p.21.2": 71.5,
      "p.14.3": 68,
      "p.14.1": 64.5,
      "p.12.3": 60,
      "p.12.1": 57,
      "centromere": 55,
      "q.11.1": 54,
      "q.12.1": 51,
      "q.13.11": 47.5,
      "q.13.13": 45,
      "q.13.31": 42,
      "q.21.1": 38,
      "q.21.3": 26,
      "q.22.2": 31,
      "q.23": 28.5,
      "q.24": 25.5,
      "q.25.2": 22,
      "q.25.33": 19,
      "q.26.2": 15.5,
      "q.26.32": 12,
      "q.27.2": 8.5,
      "q.28": 5.5,
      "q.29": 2.5,
    },
    "4": {
      "p.16.2": 97.5,
      "p.16.1": 94.5,
      "p.15.33": 91.5,
      "p.15.31": 87.5,
      "p.15.2": 85,
      "p.15.1": 82,
      "p.14": 79,
      "p.13": 766,
      "p.11": 73.5,
      "centromere": 71,
      "q.12": 70,
      "q.13.1": 67,
      "q.13.2": 64,
      "q.21.1": 60,
      "q.21.22": 57,
      "q.21.3": 54.5,
      "q.22.1": 52,
      "q.22.3": 48.5,
      "q.24": 45,
      "q.25": 42,
      "q.26": 39.5,
      "q.27": 36,
      "q.28.1": 33,
      "q.28.3": 30,
      "q.31.1": 25,
      "q.31.21": 22,
      "q.31.3": 18.5,
      "q.32.1": 15,
      "q.32.3": 12.5,
      "q.34.1": 8.5,
      "q.34.3": 5.5,
      "q.35.1": 3,
    },
    "5": {
      "p.15.32": 96.5,
      "p.15.2": 94.5,
      "p.14.3": 89.5,
      "p.14.1": 87,
      "p.13.3": 84,
      "p.13.2": 81,
      "p.12": 78,
      "centromere": 75,
      "q.11.1": 74.4,
      "q.11.2": 72,
      "q.12.1": 68,
      "q.12.3": 65,
      "q.13.2": 61,
      "q.14.1": 57.5,
      "q.14.2": 54,
      "q.14.3": 51,
      "q.15": 48,
      "q.21.1": 45,
      "Q.21.3": 42,
      "q.22.2": 38.5,
      "q.23.1": 35.5,
      "q.23.2": 33,
      "q.23.3": 30,
      "q.31.1": 27,
      "q.31.3": 23.5,
      "q.32": 20.5,
      "q.33.1": 18,
      "q.33.3": 13.5,
      "q.34": 10.5,
      "q.35.1": 7.5,
      "q.35.2": 4,
      "q.35.3": 2,
    },
    "6": {
      "p.25.2": 98,
      "p.24.3": 95.5,
      "p.23": 92,
      "p.22.3": 89,
      "p.22.1": 85.5,
      "p.21.32": 82,
      "p.21.31": 79.5,
      "p.21.1": 76,
      "p.12.3": 72,
      "p.12.2": 69,
      "p.11.2": 66,
      "centromere": 64.5,
      "q.11.2": 63,
      "q.12": 60.5,
      "q.14.1": 56,
      "q.14.2": 53.5,
      "q.15": 50.5,
      "q.16.1": 47,
      "q.16.2": 44.5,
      "q.21": 40,
      "q.22.1": 34.5,
      "q.22.31": 30.5,
      "q.22.32": 28,
      "q.22.33": 25.5,
      "q.23.1": 22,
      "q.23.3": 18,
      "q.24.2": 14,
      "q.25.1": 11,
      "q.25.3": 7.5,
      "q.26": 4,
      "q.27": 1,
    },
    "7": {
      "p.22.2": 97,
      "p.21.3": 92.5,
      "p.21.2": 90,
      "p.15.3": 87,
      "p.15.2": 84,
      "p.14.3": 79.5,
      "p.14.1": 77,
      "p.13": 74,
      "p.12.3": 71,
      "p.12.1": 68,
      "p.11.1": 63.5,
      "centromere": 63,
      "q.11.21": 60,
      "q.11.22": 56.5,
      "q.11.23": 54,
      "q.21.11": 50,
      "q.21.12": 40,
      "q.22.1": 38.5,
      "q.22.2": 33.5,
      "q.31.1": 30,
      "q.31.31": 26,
      "q.31.33": 22,
      "q.32.3": 18,
      "q.33": 15,
      "q.34": 12.5,
      "q.35": 9.5,
      "q.36.1": 6.5,
      "q.36.2": 4,
      "q.36.3": 1,
    },
    "8": {
      "p.23.2": 97.5,
      "p.23.1": 94.5,
      "p.22": 90.5,
      "p.21.3": 87,
      "p.21.2": 84,
      "p.21.1": 81,
      "p.12": 78.5,
      "p.11.22": 74,
      "p.11.1": 71,
      "centomere": 70,
      "q.11.21": 67,
      "q.11.22": 64,
      "q.11.23": 61,
      "q.12.1": 58,
      "q.12.3": 55,
      "q.13.2": 51,
      "q.13.3": 48,
      "q.21.11": 45.5,
      "q.21.13": 43,
      "q.21.3": 39,
      "q.22.1": 35,
      "q.22.2": 31.5,
      "q.23.1": 27,
      "q.23.3": 23,
      "q.24.11": 19,
      "q.24.13": 15,
      "q.24.21": 11,
      "q.24.22": 8.5,
      "q.24.3": 4,
    },
    "9": {
      "p.24.2": 98,
      "p.23": 94.5,
      "p.22.3": 90.5,
      "p.22.1": 87,
      "p.21.2": 83,
      "p.13.3": 78.5,
      "p.13.2": 75,
      "p.12": 72,
      "p.11.2": 69,
      "centromere": 65.5,
      "q.11": 65,
      "q.12": 63,
      "q.13": 55.5,
      "q.21.12": 51.5,
      "q.21.2": 48,
      "q.21.31": 45,
      "q.21.32": 42,
      "q.22.1": 38,
      "q.22.2": 35,
      "q.22.32": 32,
      "q.31.1": 28,
      "q.31.2": 25,
      "q.32": 22,
      "q.33.1": 18,
      "q.33.2": 15,
      "q.34.11": 12,
      "q.34.12": 8.5,
      "q.34.2": 4.5,
      "q.34.3": 2.5,
    },
    "10": {
      "p.15.2": 96.5,
      "p.15.1": 94.5,
      "p.14": 91,
      "p.13": 88,
      "p.12.33": 84.5,
      "p.12.31": 81,
      "p.12.1": 78.5,
      "p.11.23": 75,
      "p.11.21": 71,
      "p.11.1": 68.5,
      "centromere": 67,
      "q.11.1": 65.5,
      "q.11.21": 63,
      "q.11.23": 58.5,
      "q.21.1": 55.5,
      "q.21.2": 52.5,
      "q.21.3": 49.5,
      "q.22.1": 47,
      "q.22.2": 43.5,
      "q.22.3": 40.5,
      "q.23.1": 37,
      "q.23.2": 34,
      "q.23.31": 31,
      "q.23.33": 28.5,
      "q.24.2": 24.5,
      "q.24.33": 21,
      "q.25.1": 18,
      "q.25.3": 15,
      "q.26.12": 10.5,
      "q.26.13": 8,
      "q.26.2": 5,
      "q.26.3": 2,    
    },
    "11": {
      "p.15.5": 98,
      "p.15.4": 95,
      "p.15.3": 92.5,
      "p.15.2": 89,
      "p.15.1": 86.5,
      "p.14.3": 83.5,
      "p.14.2": 80.5,
      "p.13": 77,
      "p.12": 72,
      "p.11.2": 69.5,
      "p.11.12": 66,
      "p.11.12": 62.5,
      "centromere": 61.5,
      "q.12.1": 59,
      "q.12.2": 56,
      "q.13.1": 53,
      "q.13.2": 48.5,
      "q.13.3": 45.5,
      "q.13.4": 42.5,
      "q.13.5": 40,
      "q.14.1": 36.5,
      "q.14.3": 33,
      "q.21": 30,
      "q.22.1": 27,
      "q.22.2": 24,
      "q.22.3": 21.5,
      "q.23.2": 17,
      "q.23.3": 15,
      "q.24.1": 9,
      "q.24.2": 5,
      "q.25": 2,
    },
    "12": {
      "p.13.33": 98,
      "p.13.31": 95,
      "p.13.2": 91,
      "p.12.3": 87,
      "p.12.2": 83.5,
      "p.12.1": 81,
      "p.11.22": 77,
      "p.11.1": 74,
      "centromere": 73,
      "q.12": 71,
      "q.13.11": 65,
      "q.13.13": 61,
      "q.13.2": 56.5,
      "q.14.1": 52.5,
      "q.14.3": 49,
      "q.15": 46,
      "q.21.1": 43,
      "q.21.2": 40,
      "q.21.31": 37,
      "q.21.33": 34,
      "q.22": 31,
      "q.23.1": 27.5,
      "q.23.2": 25,
      "q.24.11": 20.5,
      "q.24.12": 17.5,
      "q.24.21": 14.5,
      "q.24.23": 11,
      "q.24.31": 8.5,
      "q.24.32": 4,
      "q.24.33": 2,
    },
    "13": {
      "p.13": 98,
      "p.12": 95,
      "p.11.2": 90,
      "p.11.1": 84,
      "centromere": 81.5,
      "q.11": 81,
      "q.12.11": 78,
      "q.12.13": 74,
      "q.12.3": 70,
      "q.13.1": 67,
      "q.13.3": 63,
      "q.14.11": 58,
      "q.14.12": 54,
      "q.14.2": 51,
      "q.21.1": 45,
      "q.21.2": 41,
      "q.21.31": 38.5,
      "q.21.33": 34.5,
      "q.22.1": 31,
      "q.22.3": 27,
      "q.31.1": 24.5,
      "q.31.2": 21,
      "q.32.1": 16.5,
      "q.32.2": 13.5,
      "q.323": 11,
      "q.33.2": 37,
      "q.34": 3,
    },
    "14": {
      "p.13": 98,
      "p.12": 94.5,
      "p.11.2": 89,
      "p.11.1": 72.5,
      "centromere": 76,
      "q.11.1": 77.5,
      "q.11.2": 73,
      "q.12": 67.5,
      "q.13.1": 62.5,
      "q.13.3": 58,
      "q.21.1": 54,
      "q.21.3": 51,
      "q.22.1": 47,
      "q.22.2": 43,
      "q.23.1": 40.5,
      "q.23.2": 37,
      "q.24.1": 34,
      "q.24.2": 31,
      "q.24.3": 25,
      "q.31.1": 22,
      "q.31.2": 19,
      "q.31.3": 16,
      "q.32.12": 13,
      "q.32.2": 10,
      "q.32.31": 6.5,
      "q.32.33": 3.5,
    },
    "15": {
      "p.13": 98,
      "p.12": 95,
      "p.11.2": 89,
      "p.11.1": 80,
      "centromere": 77,
      "q.11.1": 76.5,
      "q.11.2": 74.5,
      "q.12": 70,
      "q.13.1": 67,
      "q.13.2": 63.5,
      "q.13.3": 61,
      "q.14": 57.5,
      "q.15.1": 53.5,
      "q.15.2": 50,
      "q.15.3": 47,
      "q.21.1": 44,
      "q.21.2": 41.5,
      "q.21.3": 39,
      "q.22.1": 35.5,
      "q.22.31": 32,
      "q.22.32": 29,
      "q.23": 24.5,
      "q.24.2": 20.5,
      "q.25.1": 17.5,
      "q.25.3": 13.5,
      "q.26.1": 10,
      "q.26.2": 6,
      "q.26.3": 2.5,
    },
    "16": {
      "p.13.3": 98,
      "p.13.2": 92.5,
      "p.13.13": 87.5,
      "p.13.2": 83.5,
      "p.13.11": 80.5,
      "p.12.3": 78,
      "p.12.2": 75,
      "p.12.1": 72,
      "p.11.2": 69,
      "p.11.1": 63,
      "centormere": 60,
      "q.11.1": 59,
      "q.11.2": 56,
      "q.12.1": 46,
      "q.12.2": 41.5,
      "q.13": 38,
      "q.21": 33.5,
      "q.22.1": 27.5,
      "q.22.2": 20,
      "q.22.3": 17.5,
      "q.23.1": 14.5,
      "q.24.1": 10,
      "q.24.1": 6,
      "q.24.2": 2.5,    
    },
    "17": {
      "p.13.3": 98,
      "p.13.2": 92,
      "p.13.1": 88.5,
      "p.12": 83.5,
      "p.11.2": 79,
      "p.11.1": 69,
      "centromere": 66,
      "q.11.1": 65.5,
      "q.11.2": 62.5,
      "q.12": 56.5,
      "q.21.1": 53.5,
      "q.21.2": 49,
      "q.21.31": 46.5,
      "q.21.32": 41,
      "q.21.33": 38,
      "q.22": 35,
      "q.23.1": 31,
      "q.23.2": 28,
      "q.23.3": 25,
      "q.24.2": 21.5,
      "q.24.3": 18,
      "q.25.1": 15,
      "q.25.2": 10,
      "q.25.3": 7,
    },
    "18": {
      "p.11.32": 98,
      "p.11.31": 96,
      "p.11.23": 90,
      "p.11.22": 87,
      "p.11.21": 84.5,
      "p.11.1": 77,
      "centromere": 72,
      "q.11.1": 71.5,
      "q.11.2": 65.5,
      "q.12.1": 60,
      "q.12.2": 51.5,
      "q.12.3": 47,
      "q.21.1": 40,
      "q.21.2": 31,
      "q.21.31": 26,
      "q.21.32": 23,
      "q.21.33": 20,
      "q.22.1": 17.5,
      "q.22.2": 11,
      "q.22.3": 8.5,
      "q.23": 4,
    },
    "19": {
      "p.13.3": 98,
      "p.13.2": 85.5,
      "p.13.13": 78,
      "p.13.12": 74,
      "p.13.11": 70,
      "p.12": 61,
      "p.11": 55.5,
      "centromere": 51.5,
      "q.11": 51,
      "q.12": 47,
      "q.13.11": 41.5,
      "q.13.12": 36.5,
      "q.13.13": 33,
      "q.13.2": 27,
      "q.13.31": 24,
      "q.13.32": 21,
      "q.13.33": 17,
      "q.13.41": 10.5,
      "q.13.42": 7.5,
      "q.13.43": 3.5,
    },
    "20": {
      "p.13": 98,
      "p.12.3": 91,
      "p.12.2": 85.5,
      "p.12.1": 82.5,
      "p.11.23": 75,
      "p.11.22": 68.5,
      "p.11.21": 65,
      "p.11.1": 61,
      "centromere": 57,
      "q.11.1": 56.5,
      "q.11.21": 52,
      "q.11.22": 48,
      "q.11.23": 45,
      "q.12": 40,
      "q.13.11": 34.5,
      "q.13.12": 31,
      "q.13.13": 26,
      "q.13.2": 17.5,
      "q.13.31": 11.5,
      "q.13.32": 8.5,
      "q.13.33": 5.5,
    },
    "21": {
      "p.13": 98.5,
      "p.12": 89,
      "p.11.2": 75.5,
      "p.11.1": 65,
      "centromere": 60,
      "q.11.1": 59,
      "q.11.2": 56,
      "q.21.1": 49,
      "q.21.2": 35.5,
      "q.21.3": 31.5,
      "q.22.11": 26,
      "q.22.12": 21,
      "q.22.13": 17,
      "q.22.2": 15,
      "q.22.3": 10,
    },
    "22": {
      "p.13": 98,
      "p.12": 89,
      "P.11.2": 88.25,
      "p.11.1": 72.25,
      "centomere": 70,
      "q.11.1": 69,
      "q.11.21": 63,
      "q.11.22": 53,
      "q.11.23": 49,
      "q.12.1": 44,
      "q.12.2": 39.5,
      "q. 12.3": 33.5,
      "q. 13.1": 28,
      "q.13.2": 20,
      "q.13.31": 14,
      "q.13.32": 5.5,
      "q.13.33": 2.5,
    },
    "X": {
      "p.22.33": 98,
      "p.22.32": 95,
      "p.22.31": 92,
      "p.22.2": 88,
      "p.22.13": 86.5,
      "p.22.11": 82.5,
      "p.21.2": 79,
      "p.11.4": 75,
      "p.11.3": 71,
      "p.11.23": 68,
      "p.11.22": 65,
      "p.11.1": 62,
      "centromere": 61,
      "q.11.2": 58.5,
      "q.13.1": 55,
      "q.13.2": 51,
      "q.21.1": 47.5,
      "q.21.2": 44.5,
      "q.21.31": 42,
      "q.21.33": 39,
      "q.22.1": 36,
      "q.22.2": 33,
      "q.23": 29,
      "q.24": 26,
      "q.25": 22,
      "q.26.1": 17.5,
      "q.26.3": 13,
      "q.27.1": 11,
      "q.27.3": 7.5,
      "q.28": 5,
    },
    "Y": {
      "p.11.32": 98,
      "p.11.31": 95,
      "p.11.2": 91,
      "p.11.1": 71,
      "centromere": 69,
      "q.11.1": 68,
      "q.11.21": 64.5,
      "q.11.221": 61,
      "q.11.222": 52,
      "q.11.223": 4.75,
      "q.11.23": 40,
      "q.12": 35.5,
    },
  }
  export default locations;