import React from 'react'
import './Headers.css';

function header(props) {
    return (
        <div>
            <div className="heading">
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}

export default header
