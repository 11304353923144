import chromosome1 from '../../assets/images/chromosome1.jpg'
import chromosome2 from '../../assets/images/chromosome2.jpg'
import chromosome3 from '../../assets/images/chromosome3.jpg'
import chromosome4 from '../../assets/images/chromosome4.jpg'
import chromosome5 from '../../assets/images/chromosome5.jpg'

import chromosome7 from '../../assets/images/chromosome7.jpg'
import chromosome8 from '../../assets/images/chromosome8.jpg'

const ImageSliderData = [
{
    
    id: 1,
    image: chromosome1,
    title: 'Methodological Aspects on the Estimation of Genetic Effects of Environmental Agents in Man',
    content:'Methodological Aspects on the Estimation of Genetic Effects of Environmental Agents in Man',
    description:'24th December 2020' ,
    author:'By Times Of India',
    link:'https://www.sciencedirect.com/topics/medicine-and-dentistry/chromosome-mutation'   
},{
        
    id: 2,
    image: chromosome8,
    title: 'COVID research updates: A COVID vaccine passes a real-world test with flying colours',
    content:'Milling is the process of machining using rotary cutters to remove material by advancing a cutter into a work piece. This may be done varying direction on one or several axes, cutter head speed, and pressure.',
    description:'24th January 2021',
    author:'The Hindu Times',
    link:'https://www.nature.com/articles/d41586-020-00502-w'

    },{
        
    id: 3,
    image: chromosome7,
    title:'A human sneeze can eject droplets of fluid and potentially infectious organisms',
    content:'A lathe (/leɪð/) is a machine tool that rotates a workpiece about an axis of rotation to perform various operations such as cutting, sanding, knurling, drilling, deformation, facing, and turning, with tools that are applied to the workpiece to create an object with symmetry about that axis.[1]',
    description:'19th December 2020',
    author:'New yorker',
    link:'https://www.nejm.org/doi/full/10.1056/NEJMicm1501197'
    },{
        
    id: 4,
    image: chromosome5,
    title: 'Multisystem Inflammatory Syndrome in Children in New York State',
    content:'CNC Turning is a manufacturing process in which bars of material are held in a chuck and rotated while a tool is fed to the piece to remove material to create the desired shape. ... CNC lathes or turning centers have tooling mounted on a turret which is computer-controlled.',
    description:'4th December 2021',
    author:'Washington post',
    
    link:'https://www.nejm.org/doi/full/10.1056/NEJMoa2021756?query=featured_coronavirus'
    
    },
    
    
]


export default ImageSliderData;