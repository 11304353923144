import React,{useState,useEffect} from "react";
import Application1Data from '../../data/Application1Data';
import Modal2 from '../Modal/Modal2';

function MainImage({chromosome2, setChromosome2, chromosome}) {
    const [showModal, setShowModal] = useState(false);
    const [chromImage, setChromImage] =useState("");
    const [chromId, setChromId] =useState("");
    const [chromText, setChromText] =useState("");
    const [chromMarker, setChromMarker] = useState("");
    const [imageIndex,setImageIndex]=useState(0);


    return (
        <div>
            <div className="chromosome_body">              
                   {
                       Application1Data.map((a,i) =>(
                           <div  onClick ={()=>{ setShowModal(prev => !prev); setChromImage(a.image); setChromId(a.id); setChromText(a.text); setImageIndex(i)}} className="chromosome_box">
                               
                               <div className="image_c" >
                                 <img src={a.image} styel={{height:'100', width:'100'}} key={a.id}/> 
                                 {
                                     chromosome2 && chromosome2.map((marker,index) =>(
                                         
                                         a.id == marker.chromosome?
                                         <img src={marker.color}
                                         key={marker.id} 
                                         style={{height:20, width:20, position:'absolute',
                                         top:`${marker.marker_value}`,
                                         
                                         }}
                                         key={index}
                                         className="pins"
                                         />:
                                null
                       
                                     ))
                                 }
                            </div> 
                                <div className="h1_c"><h1>{a.id}</h1></div>
                            </div>
                           
                       ))
                   }
                  
      <Modal2 showModal={showModal}  setShowModal={setShowModal} image ={chromImage} text={chromText} chromosome={chromosome} chromId ={chromId} content={"chromosomes"} chromosome2 ={chromosome2} setChromosome2 ={setChromosome2} title={`Chromosome ${chromId}`} imageIndex={imageIndex}/> 
              </div>
            
        </div>
    )
}

export default MainImage
