import React from 'react'
import './Image_Gene.css';
import pin from '../../assets/images/pin.png';

function Image_Gene({i}) {
    return (
        <div>
                <div className="pin_style" style={{top:40}}>
                    <img src={pin}/>
                </div>
        </div>
    )
}

export default Image_Gene
